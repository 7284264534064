<template>

  <b-row class="relative bg-img relative lighter-bg channels mt-0 py-5"
         :style="{opacity:$store.getters['auth/isLoggedIn']? 1 :0}">
    <b-col cols="6" md="4" lg="3" v-for="(channel,i) in channels" @click.prevent="selectChannel(channel)"
           class="pointer achannel" :key="i"
           style="padding:1vw 4vw">
      <b-aspect :aspect="1" class="responsive">
        <div class="w-100 h-100 img-place" :style="{background:'url(\''+ channel.thumbnail +'\')'}"></div>
      </b-aspect>
      <div class="text-center py-2 mt-4 uppercase lighter--text channel-text relative">
        <b class="relative">{{ channel.name }}</b>
      </div>
    </b-col>
  </b-row>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';

export default Vue.extend({
  mounted() {
    this._debug('mounted channels');
    if (this.$store.getters['auth/isLoggedIn']) {
      this.getChannels();
    }
  },
  computed: {
    channels: {
      get() {
        return this.$store.state.channels.channels;
      },
      set(value) {
        this.$store.commit('channels/setChannels', value);
      }
    },
    channel: {
      get() {
        return this.$store.state.channels.channel;
      },
      set(value) {
        this.$store.commit('channels/setChannel', value);
      }
    }
  },

  methods: {
    selectChannel(channel) {
      this.$store.commit('channels/setChannel', channel);
      this.$store.commit('playlists/setPlaylist', {});
      this.$store.commit('playlists/setPlayPlaylist', false);
      this.$store.commit('playlists/setPlaylistIndex', 0);
      //this.$router.push({ name: 'channel', params: { id: channel.id } });
    },
    getChannels() {
      const this_ = this;
      apiClient
        .get('api/channels', {})
        .then((response) => {
          if (Array.isArray(response.data)) {
            this_.$store.commit('channels/setChannels', response.data);
            this_.channels = response.data;
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
        });
    },
    getChannel(id) {
      this.$store.commit('channels/setPlayChannel', true);
      this.$store.commit('playlists/setPlayPlaylist', false);
      this.$store.commit('playlists/setPlaylist', {});
      if (this.channels.length > 0) {
        const channel = this.channels.find(
          (f) => f.id.toString() === id.toString()
        );
        if (typeof channel === 'object') {
          // this.images = [channel.image, this.test_image];
          this.$store.commit('channels/setChannel', channel);
        }

      } else {
        this.getChannels();
      }
    },
  },
  beforeCreate() {
  },
  created() {
    this.$store.commit('player/setShow', false);
  }
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.channels {
  padding-top: 80px;
  margin-top: 44px !important;

  .row {
    justify-content: center;
    align-content: center;
    height: 60vw;
  }

  .img-place {
    filter: grayscale(1)
  }

  .achannel:hover .img-place {
    filter: grayscale(0)
  }

  .channel-text {
    &:before {
      content: "";
      background: $btnprimary;
      width: 20%;
      height: 3px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.badge-primary {
  color: #fff;
  background-color: #00607a;
}

@media(max-width: 991px) {
  .channels {
    padding: 30px 15px 40px 15px !important;
    margin-top: 0px !important;
    min-height: 100% !important;
  }
}

</style>
